
import { lang } from '@/lang'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SubDomainInput extends Vue {
  @Prop({ type: String, default: '' })
  private initialValue!: string

  @Ref() private input: any

  private inputValue = ''

  public focus () {
    this.input.focus()
  }

  private get text () {
    return lang('SUBDOMAIN_ADD_INPUT_PLACEHOLDER')
  }

  private handlerEnter () {
    this.$emit('valueChange', this.inputValue)
  }

  mounted ():void {
    this.inputValue = this.initialValue
  }
}
