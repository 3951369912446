
import { lang } from '@/lang'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { errorMessage } from '@/utils/message'
import { checkEthAddress } from '@/sdk/domain'

@Component({})
export default class UseCsvFile extends Vue {
  @Ref() private fileInput!: HTMLInputElement

  private get text () {
    return lang('SUBDOMAIN_ADD_UPLOAD')
  }

  private loading = false

  private async handlerClick () {
    this.fileInput.click()
  }

  private checkLabel (label:string) {
    return (/^([a-z0-9-._*]+)$/g).test(label)
  }

  private processFiles () {
    const input: any = this.fileInput
    const selectedFile = input.files[0]
    if (!selectedFile) return

    const nameArry = selectedFile.name.split('.')
    const name = nameArry[nameArry.length - 1].toLowerCase()

    if (name !== 'csv') {
      errorMessage('Invalid file type')
      return
    }

    const reader = new FileReader()
    reader.readAsText(selectedFile, 'utf-8')

    reader.onload = () => {
      const str: string = reader.result as string
      const rows = str.split('\n')
      rows.pop()

      try {
        const duplicate: string[] = []
        const res = rows.map(row => {
          const array = row.split(',')
          const label = array[0].trim().toLowerCase()

          // 判断重复
          if (duplicate.includes(label)) {
            errorMessage('Duplicate subdomain label: ' + label)
            throw new Error('duplicate subdomain label')
          } else {
            duplicate.push(label)
          }

          // 判断label合法
          if (!this.checkLabel(label)) {
            errorMessage('Invalid subdomain label: ' + label)
            throw new Error('Invalid subdomain label')
          }

          // 判断eth address合法
          if (array[1].trim() && !checkEthAddress(array[1].trim())) {
            errorMessage('Invalid subdomain owner: ' + array[1])
            throw new Error('Invalid subdomain owner')
          }

          return {
            label,
            owner: array[1] ? array[1].trim() : ''
          }
        })

        console.log('csv', res)
        this.$emit('onUpload', res)
      } catch (e) {
        console.log(e)
      } finally {
        this.fileInput.value = ''
      }
    }
  }
}
