
import { Vue, Component, Prop } from 'vue-property-decorator'
import { lang } from '@/lang'
import CandidateSubDomainItem from './CandidateSubDomainItem.vue'
import SubDomainInput from '@/views/SubDomain/components/SubDomainInput.vue'
import { errorMessage } from '@/utils/message'
import UseCsvFile from '@/views/SubDomain/components/UseCsvFile.vue'

@Component({
  components: {
    CandidateSubDomainItem,
    SubDomainInput,
    UseCsvFile
  }
})
export default class CandidateSubDomainList extends Vue {
  public data: Common.CandidateSubDomainItemInfo[] = [
    { label: '', owner: '' }
  ]

  private get text () {
    return lang('SUBDOMAIN_ADD_BTN')
  }

  private changeValue (value: Common.CandidateSubDomainItemInfo, index: number) {
    if (this.data[index].label !== value.label) {
      const duplicate = this.data.find((item) => {
        return item.label === value.label
      })
      if (duplicate) {
        errorMessage('duplicate subdomain')
        return
      }
    }

    this.$set(this.data, index, value)
  }

  private deleteItem (index: number) {
    this.data.splice(index, 1)
  }

  private addInput () {
    if (this.data.length === 10) return

    this.data.push({ label: '', owner: '' } as Common.CandidateSubDomainItemInfo)
  }

  private useCsv (csvData: Common.CandidateSubDomainItemInfo[]) {
    console.log(csvData)
    try {
      this.data.map(item1 => {
        const duplicate = csvData.find(item2 => {
          return item2.label === item1.label
        })
        if (duplicate) {
          errorMessage('Duplicate subdomain: ' + item1.label)
          throw new Error('Duplicate subdomain')
        }
      })

      this.data = [...csvData, ...this.data]
    } catch (e) {
      console.log(e)
    }
  }
}

