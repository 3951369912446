
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import SubDomainInput from '@/views/SubDomain/components/SubDomainInput.vue'
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import { checkIsMobile } from '@/utils/device'
import { shortAddr } from '@/utils/content'
import { checkDomain, checkEthAddress } from '@/sdk/domain'
import { errorMessage } from '@/utils/message'

@Component({
  components: { SubDomainInput }
})
export default class CandidateSubDomainItem extends Vue {
  @Prop({ type: Object, required: true })
  private info!: Common.CandidateSubDomainItemInfo

  @Ref() private subDomainInput!: SubDomainInput

  private editable = false

  private startEdit () {
    this.editable = true
    setTimeout(() => {
      this.subDomainInput.focus()
    }, 100)
  }

  private get text () {
    return {
      placeholder: lang('SUBDOMAIN_ADD_INPUT_PLACEHOLDER')
    }
  }

  private get showOwner ():string {
    if (!this.info.owner) return ''

    const res = this.info.owner
    return checkIsMobile() ? shortAddr(res) : res
  }

  private get currUser () {
    return wallet.accountId
  }

  private deleteItem () {
    this.$emit('delete')
  }

  private valueChange (value: string) {
    value = value.trim().toLowerCase()
    const array = value.split(',')
    const label = array[0]
    let owner = array[1]

    if (!(/^([a-z0-9-._*]+)$/g).test(label)) {
      errorMessage('Invalid subdomain label')
      return
    }

    if (owner && !checkEthAddress(owner)) {
      errorMessage('Invalid subdomain owner')
      return
    }

    owner = owner || ''

    this.editable = false
    this.$emit('change', { label, owner } as Common.CandidateSubDomainItemInfo)
  }
}

