
import { lang } from '@/lang'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { batchMintSubdomain, getSubdomains } from '@/sdk/domain'
import { errorMessage, successMessage } from '@/utils/message'
import EditFooter from './components/CandidateSubDomainList/EditFooter.vue'
import EditPanel from '@/components/Panel/EditPanel.vue'
import CandidateSubDomainList from './components/CandidateSubDomainList/Main.vue'
import { wallet } from '@/utils/wallet'
import { EventBus } from '@/utils/event'

@Component({
  components: {
    EditPanel,
    EditFooter,
    CandidateSubDomainList
  }
})
export default class MintSubDomainPanel extends Vue {
  @Ref() private editPanel!: EditPanel

  @Ref() private candidateList!: CandidateSubDomainList

  private get text () {
    return {
      title: lang('SUBDOMAIN_ADD_TITLE'),
      buttonAdd: lang('SUBDOMAIN_BUTTON_ADD'),
      buttonEdit: lang('SUBDOMAIN_BUTTON_EDIT'),
      tips: lang('SUBDOMAIN_ADD_TIPS'),
      label: lang('SUBDOMAIN_ADD_INPUT_LABEL'),
      placeholder: lang('SUBDOMAIN_ADD_INPUT_PLACEHOLDER'),
      buttonConfirm: lang('SUBDOMAIN_ADD_TITLE')
    }
  }

  private cancelAddSubdomain () {
    this.$eventBus.emit(EventBus.Types.SUBDOMAIN_ADD_BATCH, false)
  }

  private async handlerAddSubDomain (unload: () => unknown) {
    const domain = this.$route.params.domain
    const listRaw: Common.CandidateSubDomainItemInfo[] = JSON.parse(JSON.stringify(this.candidateList.data))
    const listNotEmpty = listRaw.filter(item => {
      return !!item.label
    })

    const listValid = listNotEmpty.map(item => {
      if (!item.owner) {
        item.owner = wallet.accountId
      }
      return item
    })
    console.log('listValid', listValid)

    if (!listValid.length) {
      unload()
      return
    }

    const duplicates: string[] = []
    const currList = await getSubdomains(domain)
    listValid.forEach(item => {
      const duplicate = currList.find(item2 => {
        return item2.name.split('.')[0] === item.label
      })

      if (duplicate) {
        duplicates.push(item.label)
      }
    })

    if (duplicates.length) {
      errorMessage('subdomain: ' + duplicates.toString() + ' was already minted')
      unload()
      return
    }

    if (listValid.length > 10) {
      errorMessage('Only a maximum of 10 subdomains can be minted at a time')
      unload()
      return
    }

    try {
      const res = await batchMintSubdomain(domain, listValid)
      this.candidateList.data = [{ label: '', owner: '' }]
      successMessage(lang('MESSAGE_ADD_SUBDOMAIN_SUCCESS'))
    } catch (e) {
      console.error(e)
      errorMessage(lang('MESSAGE_ADD_SUBDOMAIN_ERROR'))
    } finally {
      unload()
    }
  }
}
